<template>
    <div>
        <div
            v-if="product.images[0]"
            v-html="product.images[0]"
            class="mb-3"
        ></div>
        <h3 class="text-xl font-semibold leading-tight at720:text-2xl">
            {{ product.name }}
        </h3>
        <reviews-badge
            class="mt-2 text-sm"
            :rating-score="product.ratingScore"
            :rating-count="product.ratingCount"
            :star-size="6"
        ></reviews-badge>
        <event-breakdown
            v-if="product.isEvent"
            :product="product"
            class="mt-3 space-y-4"
        ></event-breakdown>
        <product-breakdown
            v-else-if="product.isProduct"
            :product="product"
            class="mt-3 space-y-4"
        ></product-breakdown>
    </div>
</template>
<script setup>
import ReviewsBadge from 'components/Global/ReviewsBadge.vue';
import EventBreakdown from './EventBreakdown.vue';
import ProductBreakdown from './ProductBreakdown.vue';

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
});
</script>
<style scoped>
:deep(img) {
    @apply rounded-md;
}
</style>
